<template>
	<div class="main">
    <van-nav-bar
      v-if='showCLAppTitle'
      title="隐私政策"
      left-arrow
      @click-left="onClickLeft"
    />
		<iframe class="iframeBox" height="100%" :src="winAddress"></iframe>
	</div>
</template>
 
<script>
export default {
	data() {
		return {
      showCLAppTitle: false,
      protoCol: window.location.protocol,//http:
			hostName: window.location.hostname,//10.6.132.166;
      winAddress:''
		}
	},
	created() {
    document.title = '隐私政策'
     if(this.hostName == 'wechattest.1jianqidong.com'){//测试
      this.winAddress = "http://10.6.132.165:81/privacywcoa.html";
    }else if(this.hostName == 'wechat.orafl.com'){//生产
      this.winAddress = 'https://flcs.1jianqidong.com/privacywcoa.html';
    }else{//本地地址
      this.winAddress = 'https://flcs.1jianqidong.com/privacywcoa.html';
      // this.winAddress = 'http://10.6.132.166:81/privacywcoa.html';
    }
	},
	mounted() {
    
	},
	computed: {
	},
	methods: {
    getphysicalReturnKey(){
      history.go(-1)
    },
    onClickLeft(){
      history.go(-1)
    }
	},
};
</script>

<style scoped>
.main{
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.iframeBox{
  /* height: 100%; */
  border: 0;
  flex: 1;
  /* margin-bottom: 1.3rem; */
}
.footerauthBottom{
  background-color: #ffffff;
  padding: 0.2rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom:0;
  z-index: 1;
}
.footerauthBtn {
  width: 6.7rem;
  height: 0.9rem;
  border-radius: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
  background-color: #e50012;
  color: #ffffff;
}
</style>
